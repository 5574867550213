@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
/* Document
 * ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
 * ========================================================================== */

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * Show the overflow in Edge 18- and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */

textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */
/*@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Lexend+Exa&display=swap');*/

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}

a {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.App {
  width: 100%;
}

.spaced {
  margin: 0 2rem;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

.store-list-row-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1em;
}
.store-list-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.store-list-image img {
  max-height: 4em;
  max-width: 4em;
}

.store-list-name {
  margin-left: 1em;
}

.store-list-add-row {
  margin-top: 1em;
}

.sm-header {
  padding: 0.8em 1.2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
}

.button-shape-rounded {
  border: 0;
  box-shadow: none;
  padding: 1rem;
  font-weight: bold;
  width: 250px;
  border-radius: 25px;
}

.button-color-primary {
  background-color: #2196f3;
  color: white;
  text-transform: uppercase;
}

.button-color-secondary {
  border: 1px solid #00bcd4;
  color: #00bcd4;
  padding: 0.8em;
  background-color: white;
  text-transform: uppercase;
}

.button-color-secondary svg {
  fill: #00bcd4;
}

.button--disabled {
  color: #d8d6d6;
  background-color: #f7f7f7;
  text-transform: uppercase;
}

.button-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-icon-container svg {
  margin-right: 1em;
}

.icon-button {
  border: 0;
  box-shadow: none;
  padding: 0.5rem;
  background-color: #fff;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 50px;
  height: 50px;
}
.spinner-small {
  width: 20px;
  height: 20px;
}

.spinner .path {
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.checkbox-container {
  display: inline-block;
  vertical-align: middle;
}

.checkbox-svg {
  fill: none;
  stroke: #00bcd4;
  stroke-width: 4px;
}

/*Hide checkbox visually but remain accessible to screen readers.
/Source: https://polished.js.org/docs/#hidevisually*/
.checkbox-input {
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 3px;
  border: 2px solid #00bcd4;
  transition: all 150ms;
}

.checkbox .checkbox-input:focus {
  box-shadow: 0 0 0 3px pink;
}

.layout-container {
  display: flex;
  min-height: calc(100vh - 50px);
  min-height: -webkit-fill-available;
  padding-bottom: 1em;
  flex-direction: column;
  flex-grow: 1;
}

.layout-bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  padding: 1em 0;
}

.layout-middle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 1em 0;
}

.feedback {
  text-align: center;
  padding-top: 2rem;
  font-size: 1rem;
}

.feedback-success {
  color: #4caf50;
}

.feedback-error {
  color: #d32f2f;
}

.location-suggestions {
  position: absolute;
  top: 1em;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.location-suggestions ul {
  background-color: white;
  list-style: none;
  padding: 0.5em;
  margin: 0;
}

.location-suggestions li {
  padding: 0.5em;
  max-width: 300px;
}

.location-suggestions-input-wrapper {
}

.location-suggestions input {
  width: 300px;
  font-size: 0.8em;
  font-family: "Lato", sans-serif;
  background: white;
  padding: 0.4em;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.store-create-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.store-form-header {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.store-form-title {
  font-size: 1.4em;
  padding-top: 0.5rem;
}

.store-form-desc {
  font-size: 1em;
  padding: 0.5rem;
  text-align: center;
}

.store-form-info {
  display: flex;
  flex-direction: column;
  padding: 1em;
  justify-content: space-around;
}
.store-form-info-row {
  padding-bottom: 0.5em;
}

.store-form-info-row input,
.store-form-info-row select,
.store-form-info-row textarea {
  width: 100%;
  font-size: 0.8em;
  font-family: "Lato", sans-serif;
  background: none;
  padding: 0.4em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #666666;
}

.store-form-info-row textarea {
  height: 4em;
}

.store-form-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.store-form-logo label {
  margin-top: 1em;
}
.store-form-logo img {
  max-width: 100%;
}

.store-form-logo input {
  display: none;
}

.store-form-logo-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.store-form-logo-placeholder-l1 {
  padding: 1.8em;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.store-form-logo-placeholder-l2 {
  padding: 2.2em;
}
.store-form-logo-btn-container {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 6em;
}

.store-form-logo-btn-hint {
  text-align: center;
  margin-top: 1em;
}

.store-form-confirm-container {
  display: flex;
  height: 70vh;
  flex-direction: column;
  align-items: center;
}

.store-form-confirm-summary-container {
  padding: 1em 0;
  margin-top: 2em;
  display: flex;
  align-items: center;
}

.store-form-confirm-summary {
  display: flex;
  margin-left: 1em;
  justify-content: space-between;
  flex-direction: column;
}

.store-form-confirm-name {
}

.store-form-confirm-goback-container {
  margin: 2em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 6rem;
}

.store-form-confirm-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}

.sm-store-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 1em 0;
}

.sm-dashboard-item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.sm-dashboard-item {
  padding: 1em 2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sm-dashboard-item--red {
  color: #e4530f;
}

.blockarea-container {
  position: relative;
}

.blockarea-blocker {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal {
  background-color: white;
  width: 70vw;
  z-index: 2;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.drawer-menu {
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #ffffff;
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  width: 90%;
  box-shadow: 1em 0 1em rgba(0, 0, 0, 0.1);
}

.drawer-store-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 2em 0;
  font-weight: bold;
  font-size: 0.875rem;
}

.drawer-menu-show {
  transform: translateX(0);
}

.drawer-menu-hide {
  transform: translateX(-110%);
}

button.menu-item {
  border-radius: unset;
  border: none;
  background-color: #fff;
  box-shadow: none;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding: 1em;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #ffffff;
}

button.menu-item:active {
  background-color: #f7f7f7;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

button.menu-item:active svg {
  stroke: #00bcd4;
}

.error-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40vh 2em 0 2em;
}

.error-text {
  /*color: #d32f2f;*/
  text-align: center;
  margin-bottom: 2em;
}

.sm-queue-table {
  max-height: 80vh;
  overflow-y: auto;
  /*background: linear-gradient(rgba(255,255,255, 1) 20%, rgba(255,255,255, 0) 80%, rgba(255,255,255, 1) 20%);*/
}

.sm-queue-empty-text {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sm-queue-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 1em 0 1em;
}
.sm-queue-info span {
  margin: 0 0.2em;
}
.sm-queue-btn {
  padding-top: 1em;
  text-align: center;
}

/*.sm-queue-table:after {*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    content: "";*/
/*    background: linear-gradient(to top, rgba(255,255,255, 1) 20%, rgba(255,255,255, 0) 80%);*/
/*    pointer-events: none; !* so the text is still selectable *!*/
/*}*/

.sm-queue-row {
  display: flex;
  font-size: 3em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.3rem 1rem;
}

.sm-queue-row-item,
.sm-queue-row-item--left,
.sm-queue-row-item--right {
  flex: 1 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.sm-queue-row-item--left {
  margin-right: auto;
}

.sm-queue-row-item--right {
  margin-left: auto;
  justify-content: flex-start;
}

.sm-queue-row-item--right img {
  margin-left: 1em;
}

.sm-queue-row-item.done {
  color: rgba(0, 0, 0, 0.35);
}

.sm-queue-row-item.just-in {
  color: #2196f3;
}

.sm-queue-row-item.next {
  font-size: 4rem;
  font-weight: bold;
  color: #00bcd4;
}

.sm-queue-item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.sm-queue-item {
  /*border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/
  display: flex;
  flex-direction: column;
}

.sm-queue-item-row {
  padding: 1em 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sm-queue-item-actions {
  padding: 1em 2em;
}

.sm-queue-list {
  margin-top: 2em;
}

.sm-queue-item-name {
  font-weight: bold;
  font-size: 1.375em;
}

.sm-queue-item-name-container-passive {
  color: #dbdbdb;
}

.sm-queue-item-prop {
  margin-right: 1em;
  font-size: 0.625em;
  text-transform: uppercase;
}

.sm-queue-item-prop-green {
  color: #4caf50;
}

.sm-queue-item-prop-red {
  color: #d32f2f;
}

.sm-queue-list-add {
  margin-top: 2em;
  text-align: center;
}

.sm-queue-item-action {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.875em;
  padding: 1em 0;
}

.sm-queue-item-action:active {
  background-color: #eeeeee;
}

.sm-queue-item-action img {
  margin-right: 1em;
  width: 1.2em;
}

.sm-queue-item-action img.sm-queue-item-icon-delete {
  width: 1em;
  margin-left: 0.1em;
}

.sm-queue-waiting-time {
  display: flex;
  justify-content: space-around;
}

.sm-queue-waiting-time-title {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 1em;
}

.sm-queue-waiting-time-input {
  width: 3em;
  font-size: 1rem;
}
.sm-queue-waiting-time-btn {
  border: 0;
  border-radius: 1.5em;
  box-shadow: none;
  padding: 0.5em 2em;
  line-height: 0.8em;
  color: white;
  background-color: #2196f3;
  cursor: pointer;
}

.sm-store-qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.sm-store-qr-id {
  font-size: 3rem;
}

@media print {
  div {
    line-height: 3em;
  }
  canvas {
    width: 400px !important;
    height: 400px !important;
  }
}

.sm-store-share-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
}

.sm-store-share-link-title {
  margin: 1rem auto;
  text-align: center;
  font-size: 1.5em;
}

.sm-store-share-link-description {
  margin-bottom: 2em;
}

.sm-store-share-link-textarea {
  margin-top: 2em;
  text-align: center;
  width: 100%;
  border: none;
  font-size: 1rem;
}

.sm-store-share-link-btn {
  margin-top: 1em;
}

.sm-store-adduser {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
}

.sm-store-adduser-ticketno {
  text-align: center;
  padding: 1rem;
  font-size: 4rem;
}

.sm-store-adduser-input input {
  width: 240px;
  font-size: 1.2em;
  font-family: "Lato", sans-serif;
  background: none;
  padding: 0.4em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #666666;
}

.sm-store-adduser-input {
  padding-top: 2em;
  text-align: center;
}
.sm-store-adduser-bottom {
  padding-top: 2em;
  text-align: center;
}

.sm-store-invite {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
}

input {
  width: 100%;
  font-size: 0.8em;
  font-family: "Lato", sans-serif;
  background: none;
  padding: 0.4em; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #666666;
}

.sm-store-invite-textarea {
  border: 1px solid #eee;
  padding: 0.4em;
  margin: 1em auto;
  height: 4em;
  width: 100%; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.sm-store-invite-input {
  padding-top: 2em;
}
.sm-store-invite-bottom {
  padding-top: 2em;
  text-align: center;
}

.store-list-row-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1em;
}
.store-list-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.store-list-image img {
  max-height: 4em;
  max-width: 4em;
}

.store-list-name {
  margin-left: 1em;
}

.store-list-add-row {
  margin-top: 1em;
}

.shopper-home-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;
}

.shopper-home-logo {
  font-family: "Lexend Exa", sans-serif;
  font-size: 54px;
  letter-spacing: -8px;
}

.shopper-home-title {
  text-align: center;
  font-size: 1.25em;
  margin: 0.5em;
}
.shopper-home-desc {
  text-align: center;
}

.shopper-home-body-container {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
}

.shopper-home-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.shopper-home-qrreader-container {
  width: 100%;
}

.shopper-home-qrreader {
  width: 100%;
}

.shopper-home-qrreader-desc {
  padding-bottom: 1em;
  text-align: center;
  line-height: 1.5em;
}

.shopper-home-qrreader-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.shopper-home-qrreader-placeholder-l1 {
  padding: 1.8em;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.shopper-home-qrreader-placeholder-l2 {
  padding: 2.2em;
}

.shopper-home-pin-container {
  width: 100%;
  text-align: center;
}

.shopper-home-pin-error {
  color: #d32f2f;
  padding: 1em;
  text-align: center;
}

.shopper-store-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
}

.shopper-store-summary-container {
  margin-top: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.shopper-store-summary-img {
  display: inline-block;
  height: 80px;
  width: 80px;
}

.shopper-store-summary-desc {
  text-align: left;
}

.shopper-store-summary-desc-title {
  font-weight: bold;
  padding-bottom: 0.5em;
}

.shopper-queue-summary-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.shopper-queue-summary {
  display: flex;
  justify-content: space-around;
  padding: 1em;
}

.shopper-queue-summary-col {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.shopper-queue-summary-text--big {
  font-size: 2em;
  font-weight: bold;
}

.shopper-queue-summary-text--small {
  font-size: 0.9em;
}

.shopper-queue-summary-text-title {
  font-size: 0.9em;
  margin-bottom: 1em;
}

.shopper-queue-summary-text-subtitle {
  text-align: center;
  font-size: 1.5em;
}

.shopper-queue-summary-text-subtitle--big {
  text-align: center;
  font-size: 2em;
}

.shopper-queue-summary-static {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 1em;
}

.shopper-user-status {
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shopper-user-status--left {
  align-items: flex-start;
}

.shopper-user-status-container {
  padding: 1em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.shopper-user-status-container-hl {
  background-color: #2196f3;
  color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.shopper-user-status-title {
  font-size: 22px;
  padding-bottom: 0.8em;
}

.shopper-user-status-desc-big {
  font-size: 100px;
}

.shopper-share-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shopper-share-item {
}

.share-button {
  border: 0;
  box-shadow: none;
  padding: 0.5em;
  font-size: 1.2rem;
  line-height: 0.8em;
  width: 300px;
  border-radius: 25px;
  margin-top: 2em;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.share-button-icon {
  width: 1.2em;
  height: 1.2em;
  padding-left: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.2rem;
  fill: white;
  margin-right: 0.8em;
}
.share-button-text {
  padding: 0.2rem 1rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  border-left: 1px solid #ccc;
}

.share-button-facebook {
  background-color: #4267b2;
}

.share-button-twitter {
  background-color: #1da1f2;
}

.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 2em;
}

.auth-buttons {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.auth-button {
  border: 0;
  box-shadow: none;
  padding: 0.5em;
  font-size: 1.2rem;
  line-height: 0.8em;
  width: 300px;
  border-radius: 25px;
  margin-top: 2em;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.auth-button-icon {
  width: 1.2em;
  height: 1.2em;
  padding-left: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.2rem;
  fill: white;
  margin-right: 0.8em;
}
.auth-button-text {
  padding: 0.2rem 1rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  border-left: 1px solid #ccc;
}

.auth-button-google {
  background-color: #4285f4;
}

.auth-button-facebook {
  background-color: #4267b2;
}

.auth-button-twitter {
  background-color: #1da1f2;
}

.auth-bottom {
  text-align: center;
}

.cookie_consent-body {
  line-height: 1.5em;
}
.cookie_consent-body a {
  color: #2196f3;
}
.cookie_consent-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 2em;
}

.cookie_consent-btn--accept {
  cursor: pointer;
  padding: 0.5em 1em;
  display: inline;
  color: white;
  border-radius: 1.5em;
  background-color: #2196f3;
}

.cookie_consent-btn--reject {
  cursor: pointer;
  display: inline;
  text-decoration: underline;
  color: #333;
}

.app_update-body {
  line-height: 1.5em;
}
.app_update-body a {
  color: #2196f3;
}
.app_update-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 2em;
}

.app_update-btn--accept {
  cursor: pointer;
  padding: 0.5em 1em;
  display: inline;
  color: white;
  border-radius: 1.5em;
  background-color: #2196f3;
}

.app_update-btn--reject {
  cursor: pointer;
  display: inline;
  text-decoration: underline;
  color: #333;
}

