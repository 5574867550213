.button-shape-rounded {
  border: 0;
  box-shadow: none;
  padding: 1rem;
  font-weight: bold;
  width: 250px;
  border-radius: 25px;
}

.button-color-primary {
  background-color: #2196f3;
  color: white;
  text-transform: uppercase;
}

.button-color-secondary {
  border: 1px solid #00bcd4;
  color: #00bcd4;
  padding: 0.8em;
  background-color: white;
  text-transform: uppercase;
}

.button-color-secondary svg {
  fill: #00bcd4;
}

.button--disabled {
  color: #d8d6d6;
  background-color: #f7f7f7;
  text-transform: uppercase;
}

.button-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-icon-container svg {
  margin-right: 1em;
}

.icon-button {
  border: 0;
  box-shadow: none;
  padding: 0.5rem;
  background-color: #fff;
}
