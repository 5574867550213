.shopper-queue-summary-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.shopper-queue-summary {
  display: flex;
  justify-content: space-around;
  padding: 1em;
}

.shopper-queue-summary-col {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.shopper-queue-summary-text--big {
  font-size: 2em;
  font-weight: bold;
}

.shopper-queue-summary-text--small {
  font-size: 0.9em;
}

.shopper-queue-summary-text-title {
  font-size: 0.9em;
  margin-bottom: 1em;
}

.shopper-queue-summary-text-subtitle {
  text-align: center;
  font-size: 1.5em;
}

.shopper-queue-summary-text-subtitle--big {
  text-align: center;
  font-size: 2em;
}

.shopper-queue-summary-static {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 1em;
}
