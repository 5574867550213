.location-suggestions {
  position: absolute;
  top: 1em;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.location-suggestions ul {
  background-color: white;
  list-style: none;
  padding: 0.5em;
  margin: 0;
}

.location-suggestions li {
  padding: 0.5em;
  max-width: 300px;
}

.location-suggestions-input-wrapper {
}

.location-suggestions input {
  width: 300px;
  font-size: 0.8em;
  font-family: "Lato", sans-serif;
  background: white;
  padding: 0.4em;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
