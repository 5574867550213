.shopper-home-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;
}

.shopper-home-logo {
  font-family: "Lexend Exa", sans-serif;
  font-size: 54px;
  letter-spacing: -8px;
}

.shopper-home-title {
  text-align: center;
  font-size: 1.25em;
  margin: 0.5em;
}
.shopper-home-desc {
  text-align: center;
}

.shopper-home-body-container {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
}

.shopper-home-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.shopper-home-qrreader-container {
  width: 100%;
}

.shopper-home-qrreader {
  width: 100%;
}

.shopper-home-qrreader-desc {
  padding-bottom: 1em;
  text-align: center;
  line-height: 1.5em;
}

.shopper-home-qrreader-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.shopper-home-qrreader-placeholder-l1 {
  padding: 1.8em;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.shopper-home-qrreader-placeholder-l2 {
  padding: 2.2em;
}

.shopper-home-pin-container {
  width: 100%;
  text-align: center;
}

.shopper-home-pin-error {
  color: #d32f2f;
  padding: 1em;
  text-align: center;
}
