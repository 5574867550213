.sm-queue-item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.sm-queue-item {
  /*border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/
  display: flex;
  flex-direction: column;
}

.sm-queue-item-row {
  padding: 1em 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sm-queue-item-actions {
  padding: 1em 2em;
}

.sm-queue-list {
  margin-top: 2em;
}

.sm-queue-item-name {
  font-weight: bold;
  font-size: 1.375em;
}

.sm-queue-item-name-container-passive {
  color: #dbdbdb;
}

.sm-queue-item-prop {
  margin-right: 1em;
  font-size: 0.625em;
  text-transform: uppercase;
}

.sm-queue-item-prop-green {
  color: #4caf50;
}

.sm-queue-item-prop-red {
  color: #d32f2f;
}

.sm-queue-list-add {
  margin-top: 2em;
  text-align: center;
}

.sm-queue-item-action {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.875em;
  padding: 1em 0;
}

.sm-queue-item-action:active {
  background-color: #eeeeee;
}

.sm-queue-item-action img {
  margin-right: 1em;
  width: 1.2em;
}

.sm-queue-item-action img.sm-queue-item-icon-delete {
  width: 1em;
  margin-left: 0.1em;
}

.sm-queue-waiting-time {
  display: flex;
  justify-content: space-around;
}

.sm-queue-waiting-time-title {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 1em;
}

.sm-queue-waiting-time-input {
  width: 3em;
  font-size: 1rem;
}
.sm-queue-waiting-time-btn {
  border: 0;
  border-radius: 1.5em;
  box-shadow: none;
  padding: 0.5em 2em;
  line-height: 0.8em;
  color: white;
  background-color: #2196f3;
  cursor: pointer;
}
