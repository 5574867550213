.sm-store-invite {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
}

input {
  width: 100%;
  font-size: 0.8em;
  font-family: "Lato", sans-serif;
  background: none;
  padding: 0.4em;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #666666;
}

.sm-store-invite-textarea {
  border: 1px solid #eee;
  padding: 0.4em;
  margin: 1em auto;
  height: 4em;
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.sm-store-invite-input {
  padding-top: 2em;
}
.sm-store-invite-bottom {
  padding-top: 2em;
  text-align: center;
}
