.sm-dashboard-item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.sm-dashboard-item {
  padding: 1em 2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sm-dashboard-item--red {
  color: #e4530f;
}
