.sm-store-qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.sm-store-qr-id {
  font-size: 3rem;
}

@media print {
  div {
    line-height: 3em;
  }
  canvas {
    width: 400px !important;
    height: 400px !important;
  }
}
