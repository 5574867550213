.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal {
  background-color: white;
  width: 70vw;
  z-index: 2;
  padding: 1em;
  display: flex;
  flex-direction: column;
}
