.error-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40vh 2em 0 2em;
}

.error-text {
  /*color: #d32f2f;*/
  text-align: center;
  margin-bottom: 2em;
}
