.sm-queue-table {
  max-height: 80vh;
  overflow-y: auto;
  /*background: linear-gradient(rgba(255,255,255, 1) 20%, rgba(255,255,255, 0) 80%, rgba(255,255,255, 1) 20%);*/
}

.sm-queue-empty-text {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sm-queue-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 1em 0 1em;
}
.sm-queue-info span {
  margin: 0 0.2em;
}
.sm-queue-btn {
  padding-top: 1em;
  text-align: center;
}

/*.sm-queue-table:after {*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    content: "";*/
/*    background: linear-gradient(to top, rgba(255,255,255, 1) 20%, rgba(255,255,255, 0) 80%);*/
/*    pointer-events: none; !* so the text is still selectable *!*/
/*}*/

.sm-queue-row {
  display: flex;
  font-size: 3em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.3rem 1rem;
}

.sm-queue-row-item,
.sm-queue-row-item--left,
.sm-queue-row-item--right {
  flex: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.sm-queue-row-item--left {
  margin-right: auto;
}

.sm-queue-row-item--right {
  margin-left: auto;
  justify-content: flex-start;
}

.sm-queue-row-item--right img {
  margin-left: 1em;
}

.sm-queue-row-item.done {
  color: rgba(0, 0, 0, 0.35);
}

.sm-queue-row-item.just-in {
  color: #2196f3;
}

.sm-queue-row-item.next {
  font-size: 4rem;
  font-weight: bold;
  color: #00bcd4;
}
