.store-create-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.store-form-header {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.store-form-title {
  font-size: 1.4em;
  padding-top: 0.5rem;
}

.store-form-desc {
  font-size: 1em;
  padding: 0.5rem;
  text-align: center;
}

.store-form-info {
  display: flex;
  flex-direction: column;
  padding: 1em;
  justify-content: space-around;
}
.store-form-info-row {
  padding-bottom: 0.5em;
}

.store-form-info-row input,
.store-form-info-row select,
.store-form-info-row textarea {
  width: 100%;
  font-size: 0.8em;
  font-family: "Lato", sans-serif;
  background: none;
  padding: 0.4em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #666666;
}

.store-form-info-row textarea {
  height: 4em;
}

.store-form-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.store-form-logo label {
  margin-top: 1em;
}
.store-form-logo img {
  max-width: 100%;
}

.store-form-logo input {
  display: none;
}

.store-form-logo-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.store-form-logo-placeholder-l1 {
  padding: 1.8em;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.store-form-logo-placeholder-l2 {
  padding: 2.2em;
}
.store-form-logo-btn-container {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 6em;
}

.store-form-logo-btn-hint {
  text-align: center;
  margin-top: 1em;
}

.store-form-confirm-container {
  display: flex;
  height: 70vh;
  flex-direction: column;
  align-items: center;
}

.store-form-confirm-summary-container {
  padding: 1em 0;
  margin-top: 2em;
  display: flex;
  align-items: center;
}

.store-form-confirm-summary {
  display: flex;
  margin-left: 1em;
  justify-content: space-between;
  flex-direction: column;
}

.store-form-confirm-name {
}

.store-form-confirm-goback-container {
  margin: 2em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 6rem;
}

.store-form-confirm-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}
