.app_update-body {
  line-height: 1.5em;
}
.app_update-body a {
  color: #2196f3;
}
.app_update-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 2em;
}

.app_update-btn--accept {
  cursor: pointer;
  padding: 0.5em 1em;
  display: inline;
  color: white;
  border-radius: 1.5em;
  background-color: #2196f3;
}

.app_update-btn--reject {
  cursor: pointer;
  display: inline;
  text-decoration: underline;
  color: #333;
}
