.layout-container {
  display: flex;
  min-height: calc(100vh - 50px);
  min-height: -webkit-fill-available;
  padding-bottom: 1em;
  flex-direction: column;
  flex-grow: 1;
}

.layout-bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  padding: 1em 0;
}

.layout-middle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 1em 0;
}
