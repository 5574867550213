.sm-store-share-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
}

.sm-store-share-link-title {
  margin: 1rem auto;
  text-align: center;
  font-size: 1.5em;
}

.sm-store-share-link-description {
  margin-bottom: 2em;
}

.sm-store-share-link-textarea {
  margin-top: 2em;
  text-align: center;
  width: 100%;
  border: none;
  font-size: 1rem;
}

.sm-store-share-link-btn {
  margin-top: 1em;
}
