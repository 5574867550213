.checkbox-container {
  display: inline-block;
  vertical-align: middle;
}

.checkbox-svg {
  fill: none;
  stroke: #00bcd4;
  stroke-width: 4px;
}

/*Hide checkbox visually but remain accessible to screen readers.
/Source: https://polished.js.org/docs/#hidevisually*/
.checkbox-input {
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 3px;
  border: 2px solid #00bcd4;
  transition: all 150ms;
}

.checkbox .checkbox-input:focus {
  box-shadow: 0 0 0 3px pink;
}
