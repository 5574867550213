.store-list-row-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1em;
}
.store-list-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.store-list-image img {
  max-height: 4em;
  max-width: 4em;
}

.store-list-name {
  margin-left: 1em;
}

.store-list-add-row {
  margin-top: 1em;
}
