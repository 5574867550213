.sm-store-adduser {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
}

.sm-store-adduser-ticketno {
  text-align: center;
  padding: 1rem;
  font-size: 4rem;
}

.sm-store-adduser-input input {
  width: 240px;
  font-size: 1.2em;
  font-family: "Lato", sans-serif;
  background: none;
  padding: 0.4em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #666666;
}

.sm-store-adduser-input {
  padding-top: 2em;
  text-align: center;
}
.sm-store-adduser-bottom {
  padding-top: 2em;
  text-align: center;
}
