.shopper-user-status {
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shopper-user-status--left {
  align-items: flex-start;
}

.shopper-user-status-container {
  padding: 1em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.shopper-user-status-container-hl {
  background-color: #2196f3;
  color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.shopper-user-status-title {
  font-size: 22px;
  padding-bottom: 0.8em;
}

.shopper-user-status-desc-big {
  font-size: 100px;
}
