.drawer-menu {
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #ffffff;
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  width: 90%;
  box-shadow: 1em 0 1em rgba(0, 0, 0, 0.1);
}

.drawer-store-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 2em 0;
  font-weight: bold;
  font-size: 0.875rem;
}

.drawer-menu-show {
  transform: translateX(0);
}

.drawer-menu-hide {
  transform: translateX(-110%);
}

button.menu-item {
  border-radius: unset;
  border: none;
  background-color: #fff;
  box-shadow: none;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding: 1em;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #ffffff;
}

button.menu-item:active {
  background-color: #f7f7f7;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

button.menu-item:active svg {
  stroke: #00bcd4;
}
