@import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
/*@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Lexend+Exa&display=swap');*/

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}

a {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.App {
  width: 100%;
}

.spaced {
  margin: 0 2rem;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
