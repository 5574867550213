.shopper-store-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
}

.shopper-store-summary-container {
  margin-top: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.shopper-store-summary-img {
  display: inline-block;
  height: 80px;
  width: 80px;
}

.shopper-store-summary-desc {
  text-align: left;
}

.shopper-store-summary-desc-title {
  font-weight: bold;
  padding-bottom: 0.5em;
}
