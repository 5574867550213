.feedback {
  text-align: center;
  padding-top: 2rem;
  font-size: 1rem;
}

.feedback-success {
  color: #4caf50;
}

.feedback-error {
  color: #d32f2f;
}
