.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 2em;
}

.auth-buttons {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.auth-button {
  border: 0;
  box-shadow: none;
  padding: 0.5em;
  font-size: 1.2rem;
  line-height: 0.8em;
  width: 300px;
  border-radius: 25px;
  margin-top: 2em;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.auth-button-icon {
  width: 1.2em;
  height: 1.2em;
  padding-left: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.2rem;
  fill: white;
  margin-right: 0.8em;
}
.auth-button-text {
  padding: 0.2rem 1rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  border-left: 1px solid #ccc;
}

.auth-button-google {
  background-color: #4285f4;
}

.auth-button-facebook {
  background-color: #4267b2;
}

.auth-button-twitter {
  background-color: #1da1f2;
}

.auth-bottom {
  text-align: center;
}
